import { inject } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthService } from './auth.service';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpHeaders,
    HttpRequest,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const CF_Access_Client_Id = 'f060356a371e92ea14224106e04f2ac9.access';
    const CF_Access_Client_Secret =
        '8acf9728ad2ef62dd39c22b35dcb38d370cb4bea04a94369cfab3141e37b70d9';

    const authService = inject(AuthService);

    // Clone the request object
    let newReq = req.clone();
    // If value exist on localStorage, use it
    let headerValue = req.headers;

    // Request
    if (
        authService.authenticated === 'true' &&
        authService.accessToken &&
        !req.headers.has('excludeAuth')
    ) {
        headerValue = headerValue.append(
            'Authorization',
            `Bearer ${authService.accessToken}`
        );
        headerValue = headerValue.append(
            'X-API-Key',
            'AC-AoduGT1j7X2ew2Ab1Hpi7IkqciOE3UTKnnSEN6pd9Sp5ighSKTQhrEK3fpLio'
        );
    }
    if (req.headers.has('excludeAuth')) {
        headerValue = headerValue.delete('excludeAuth'); // Elimina solo la cabecera excludeAuth
    }

    newReq = req.clone({ headers: headerValue });

    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                authService.signOut();
                // Reload the app
                //location.reload();
            }

            return throwError(error);
        })
    );
};

export const isIncludedInWhiteList = (
    request,
    whiteListDomains: string[]
): boolean => {
    try {
        let requestUrl = new URL(request.url);
        return (
            whiteListDomains.findIndex((domain: any) => {
                return typeof domain === 'string'
                    ? domain === requestUrl.host
                    : domain instanceof RegExp
                    ? domain.test(requestUrl.host)
                    : false;
            }) > -1
        );
    } catch (err) {
        // If we're here, the request will be made configuration as the Angular app.
        return true;
    }
};

/**
 * Check if pathname e.g. `/account/login/` is included in Black routes.
 * @param request
 */
export const isIncludedInBlackRoutes = (
    request,
    blackListRoutes: string[]
): boolean => {
    let requestUrl = new URL(request.url);
    return (
        blackListRoutes.findIndex(
            (path) => requestUrl.pathname.indexOf(path) >= 0
        ) > -1
    );
};
