import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterModule,
    RouterOutlet,
} from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { UserInfo } from '@core/models/user';
import { MsgBoxService } from '@core/msg-box/msg-box.service';
import { environment } from '@environments/environment';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { MAT_SVG_ICON, routeMap } from '@utils/constants';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    styleUrls: ['./classy.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        NotificationsComponent,
        UserComponent,
        NgIf,
        NgFor,
        MatIconModule,
        MatButtonModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        SearchComponent,
        ShortcutsComponent,
        MessagesComponent,
        RouterOutlet,
        QuickChatComponent,
        MatMenuModule,
        MatDividerModule,
        RouterModule,
        MatTooltipModule
    ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    user: UserInfo;
    navigation: Navigation;
    ICONS = MAT_SVG_ICON;

    menu = [
        { id: 1, icon: MAT_SVG_ICON.SECURITY, label: 'Cambiar contraseña' },
        { id: 2, icon: MAT_SVG_ICON.SIGN_OUT, label: 'Cerrar sesión' },
    ];

    isScreenSmall: boolean;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    hostFile = environment.hostFile;

    version = environment.version;
    breadcrumbs: Array<{ label: string, url: string }> = [];
    public routeMap= routeMap;
    isUserLoaded: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _authService: AuthService,
        public _msgBox: MsgBoxService,
        private _userService: UserService,
        private _activatedRoute: ActivatedRoute,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              this.breadcrumbs = this.createBreadcrumbs(this._activatedRoute.root);
            }
          });
        // Subscribe to the user service
        this._authService.session
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user) => {
                this.user = user;
                this.isUserLoaded = true;
                this.breadcrumbs = this.createBreadcrumbs(this._activatedRoute.root);
            });

        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;

                this._authService.permissionModule.subscribe({
                    next: (result) => {
                        let modules = result;

                        // Loop group type
                        this.navigation.default.forEach((element) => {
                            if (element?.children?.length) {
                                // Loop collapsable type
                                element.children.forEach((item) => {
                                    if (item?.link && item?.type == 'basic') {
                                        let hideModule = modules.find(
                                            (v) => item.id == v.module_code
                                        );
                                        // Show/hide menu title and items
                                        if (this.user?.is_hyperadmin) {
                                            element.hidden = () => false;
                                            item.hidden = () => false;
                                        } else if (hideModule) {
                                            element.hidden = () => false;
                                            item.hidden = () => false;
                                        } else {
                                            item.hidden = () => true;
                                        }
                                    }

                                    if (item?.children?.length) {
                                        item.children.forEach((value) => {
                                            if (
                                                value?.link &&
                                                value?.type == 'basic'
                                            ) {
                                                let hideModule = modules.find(
                                                    (v) =>
                                                        value.id ==
                                                        v.module_code
                                                );
                                                // Show/hide menu title and items
                                                if (this.user?.is_hyperadmin) {
                                                    element.hidden = () =>
                                                        false;
                                                    item.hidden = () => false;
                                                    value.hidden = () => false;
                                                } else if (hideModule) {
                                                    element.hidden = () =>
                                                        false;
                                                    item.hidden = () => false;
                                                    value.hidden = () => false;
                                                } else {
                                                    value.hidden = () => true;
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    },
                });
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

            this._changeDetectorRef.detectChanges()
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    setActiveLang(id: number): void {
        const onAction = {
            1: () => this.profile(),
            2: () => this.signOut(),
        };

        onAction[id]();
    }

    signOut(): void {
        this._msgBox
            .confirm('Cerrar sesión', '¿Esta seguro que desea cerrar sesión?')
            .then((res) => {
                if (res) {
                    this._authService.signOut();
                }
            });
    }

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    profile(): void {
        this._router.navigateByUrl('/main/profile');
    }

    private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Array<{ label: string, url: string }> = []): Array<{ label: string, url: string }> {
        const children: ActivatedRoute[] = route.children;
    
        if (children.length === 0) {
          return breadcrumbs;
        }
    
        for (const child of children) {
          const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
          if (routeURL !== '') {
            url += `/${routeURL}`;
            const label = this.routeMap[routeURL] || routeURL; // Usa el mapeo si existe, si no, usa el valor literal
            breadcrumbs.push({ label, url });
          }
    
          return this.createBreadcrumbs(child, url, breadcrumbs);
        }
    
        return breadcrumbs;
      }
}
