import packageInfo from '../../package.json';

export const environment = {
    production: false,
    host: 'https://api-dev.autocore.pro/',
    hostFile: 'https://api-dev.autocore.pro/api/v1/management/media/',
    whiteListDomains: ['api-dev.autocore.pro'],
    blackListRoutes: [
        '/account/sign-in',
        '/account/sign-up',
        '/account/forgot-password',
        '/account/confirmation-required',
    ],
    company_name: 'Autocore',
    sentryDSN:
        'https://655ef60c08ce4e754acd403da20ad25e@o4506954943823872.ingest.us.sentry.io/4506954962305024',
    sentryEnv: 'development',
    tagName: 'development',
    merchantId: '7779316136738766090',
    merchantSiteId: '252388',
    envNuvei:'int',
    appAuto: 'https://app-dev.autocore.pro/',
    version: packageInfo.version,
};
